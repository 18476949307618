<template>
  <div>
    <v-card class="mx-auto px-6 py-8">
      <v-card-title primary-title class="justify-center">
        <v-img :src="logo_url" max-width="250px" alt="wwcalc"/>
        <!-- <p style="padding: 10px; margin-bottom: 30px;">Welcome to WWCALC application.</p> -->
      </v-card-title>
      <div v-if="!registered">
        <v-form v-model="form">
          <v-row>
            <v-col cols="12" sm="6">
              <v-text-field v-model="email" :readonly="loading" :rules="[rules.email]" class="mb-2" :label="selectedLanguage['register_component_text1']" :placeholder="selectedLanguage['register_component_text2']"/>
              <v-text-field v-model="password" :readonly="loading" :rules="rules.password" type="password" :label="selectedLanguage['register_component_text3']" :placeholder="selectedLanguage['register_component_text4']"/>
              <v-text-field v-model="confirmPassword" :readonly="loading" :rules="[rules.required]" type="password" :label="selectedLanguage['register_component_text5']" :placeholder="selectedLanguage['register_component_text6']"/>
              <v-text-field v-model="first_name" :readonly="loading" :rules="[rules.required]" class="mb-2" :label="selectedLanguage['register_component_text7']" :placeholder="selectedLanguage['register_component_text8']"/>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field v-model="last_name" :readonly="loading" class="mb-2" :label="selectedLanguage['register_component_text9']" :placeholder="selectedLanguage['register_component_text10']"/>  
              <v-text-field v-model="phone" :readonly="loading" class="mb-2" :label="selectedLanguage['register_component_text11']" :placeholder="selectedLanguage['register_component_text12']"/>
              <v-text-field v-model="civil_id" @input="formatCPF" :readonly="loading" :rules="[rules.civil_id]" class="mb-2" :label="selectedLanguage['register_component_text13']" :placeholder="selectedLanguage['register_component_text14']"/>
              <v-text-field v-model="work_id" :readonly="loading" class="mb-2" :label="selectedLanguage['register_component_text15']" :placeholder="selectedLanguage['register_component_text16']"/>
              <!-- <v-select v-model="currency" :items="currencyList" label="Currency" :rules="[rules.required]" clearable/> -->
            </v-col>
            <!-- <v-text-field v-model="currency" :readonly="loading" class="mb-2" label="Currency (optional)" placeholder="Your default currency"/> -->
            <!-- <v-text-field v-model="exchange" :readonly="loading" :rules="[rules.required]" class="mb-2" placeholder="Your default exchange"/> -->
            <v-btn v-if="!readTerms" @click="checkFormData" block color="primary" size="large">{{ selectedLanguage['register_component_text17'] }}</v-btn>
            <div class="overlay" v-if="formMessage">
              <v-alert border="top" dense color="red lighten-2" dark>
                {{formMessage}}
              </v-alert>
            </div>
            <v-btn v-if="readTerms" @click="readTerms=!readTerms" block color="primary" size="large">{{ selectedLanguage['register_component_text18'] }}</v-btn>
          </v-row>
        </v-form>
        <br>
        <div v-if="this.selectedLanguage['profile_form_name_1'] === 'Nome'">
          <TermsOfUsept v-if="readTerms"/>
          <PrivacyPolicypt v-if="readTerms"/>
        </div>
        <div v-else>
          <TermsOfUse v-if="readTerms"/>
          <PrivacyPolicy v-if="readTerms"/>
        </div>
        <v-checkbox v-show="readTerms" v-model="terms_agreement" :rules="[rules.required]" :label="selectedLanguage['register_component_text19']"/>
        <v-checkbox v-show="readTerms" v-model="policy_agreement" :rules="[rules.required]" :label="selectedLanguage['register_component_text20']"/>
        <br>
        <v-btn v-if="readTerms" :loading="loading" block color="primary" size="large" type="submit" variant="elevated" @click="registerClick">
          {{ selectedLanguage['register_component_text21'] }}
        </v-btn>
        <div class="overlay" v-if="registerMessage">
          <v-alert border="top" dense color="red lighten-2" dark>
            {{registerMessage}}
          </v-alert>
        </div>
      </div>
      <div class="overlay" v-else>
        <v-alert border="top" color="info" dark>
          {{ selectedLanguage['register_component_text22'] }}<br>
          {{ selectedLanguage['register_component_text23'] }}<br>
          {{ selectedLanguage['register_component_text24'] }}
        </v-alert>
      </div>
      <br>
      <v-row justify="center">
        <v-btn color="secondary" size="large" variant="elevated" @click="$router.push({ name: 'login' })">
          {{ selectedLanguage['register_component_text25'] }}
        </v-btn>
      </v-row>
    </v-card>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'
  import TermsOfUse from './TermsOfUse.vue'
  import PrivacyPolicy from './PrivacyPolicy.vue'
  import TermsOfUsept from './TermsOfUsept.vue'
  import PrivacyPolicypt from './PrivacyPolicypt.vue'
  import { portuguese } from '../languages/pt-br'
  import { english } from '../languages/en'
  
  export default {
    name: 'Login',
    components: {TermsOfUse, PrivacyPolicy,TermsOfUsept,PrivacyPolicypt},
    mounted: async function(){
      await this.initRegisterRules()
    },
    computed:{
      ...mapGetters(['profile']),
      selectedLanguage() {
        if(this.profile != undefined){
          if (this.profile['language'] === 'en') {
            return english;
          } else {
            return portuguese;
          }
        } else if(localStorage.getItem('loginLanguage')){
          if(localStorage.getItem('loginLanguage') === 'en'){
            return english;
          } else {
            return portuguese;
          }
        } else {
          return portuguese;
        }
        },
      // rules() {
      //   return {
      //     required: value => !!value || this.register_rules_txt_1,
      //     password: [
      //       value => !!value || this.register_rules_txt_2,
      //       (value) => (value && /[^A-Za-z0-9]/.test(value)) || this.register_rules_txt_4,
      //       (value) => (value && value.length > 9) || this.register_rules_txt_5,
      //     ],
      //     email: value => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value) || this.register_rules_txt_6,
      //     civil_id: value => this.validarCPF(value) || this.register_rules_txt_7
      //   };
      // },
    },
    data: () => ({
      logo_url: '/img/logo_color.png',
      readTerms: false,
      registered: false,
      form: false,
      email: undefined,
      password: undefined,
      confirmPassword: undefined,
      first_name: undefined,
      last_name: '',
      phone: '',
      civil_id: '',
      work_id: '',
      currency: 'BRL',
      exchange: '',
      terms_agreement: false,
      policy_agreement: false,
      loading: false,
      formMessage: undefined,
      registerMessage: undefined,
      register_rules_txt_1: "Campo obrigatório",
      register_rules_txt_2: "Porfavor digite sua senha.",
      register_rules_txt_3: "Porfavor digite sua senha.",
      register_rules_txt_4: "É necessario possuir ao menos um caractere especial",
      register_rules_txt_5: "Mínimo 10 caracteres",
      register_rules_txt_6: "O e-mail deve ser válido",
      register_rules_txt_7: "CPF inválido",
      rules: {
        required: value => !!value || "Campo obrigatório",
        password: [
          value => !!value || "Porfavor digite sua senha.",
          (value) => !!value || "Porfavor digite sua senha.",
          // (value) => (value && /\d/.test(value)) || 'At least one digit',
          // (value) => (value && /[A-Z]{1}/.test(value)) || 'At least one capital latter',
          (value) => (value && /[^A-Za-z0-9]/.test(value)) || "É necessario possuir ao menos um caractere especial",
          (value) => (value && value.length > 9 ) || "Mínimo 10 caracteres",
        ],
        // equalPassword: value => value===this.password || 'Please reenter password',
        email: value => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value) || "O e-mail deve ser válido",
        // civil_id: value => this.validarCPF(value) || "CPF inválido"
      },
      // currencyList:[
      //   'AFN','EUR','ALL','DZD','USD','AOA','XCD','ARS','AMD','AWG','AUD','AZN','BSD','BHD','BDT','BBD','BYN','BZD','XOF','BMD','INR','BTN','BOB','BOV','BAM','BWP','NOK','BRL','BND','BGN','BIF','CVE','KHR','XAF','CAD','KYD','CLP','CLF','CNY','COP','COU','KMF','CDF','NZD','CRC','HRK','CUP','CUC','ANG','CZK','DKK','DJF','DOP','EGP','SVC','ERN','ETB','FKP','FJD','XPF','GMD','GEL','GHS','GIP','GTQ','GBP','GNF','GYD','HTG','HNL','HKD','HUF','ISK','IDR','XDR','IRR','IQD','ILS','JMD','JPY','JOD','KZT','KES','KPW','KRW','KWD','KGS','LAK','LBP','LSL','ZAR','LRD','LYD','CHF','MOP','MKD','MGA','MWK','MYR','MVR','MRU','MUR','XUA','MXN','MXV','MDL','MNT','MAD','MZN','MMK','NAD','NPR','NIO','NGN','OMR','PKR','PAB','PGK','PYG','PEN','PHP','PLN','QAR','RON','RUB','RWF','SHP','WST','STN','SAR','RSD','SCR','SLL','SGD','XSU','SBD','SOS','SSP','LKR','SDG','SRD','SZL','SEK','CHE','CHW','SYP','TWD','TJS','TZS','THB','TOP','TTD','TND','TRY','TMT','UGX','UAH','AED','USN','UYU','UYI','UYW','UZS','VUV','VES','VND','YER','ZMW','ZWL','XBA','XBB','XBC','XBD','XTS','XXX','XAU','XPD','XPT','XAG',
      // ],
    }),
    methods: {
      initRegisterRules(){
        this.register_rules_txt_1 = this.selectedLanguage['register_rules_txt_1']
        this.register_rules_txt_2 = this.selectedLanguage['register_rules_txt_2']
        this.register_rules_txt_3 = this.selectedLanguage['register_rules_txt_3']
        this.register_rules_txt_4 = this.selectedLanguage['register_rules_txt_4']
        this.register_rules_txt_5 = this.selectedLanguage['register_rules_txt_5']
        this.register_rules_txt_6 = this.selectedLanguage['register_rules_txt_6']
        this.register_rules_txt_7 = this.selectedLanguage['register_rules_txt_7']
        this.countries = this.selectedLanguage['countries_list']

        this.rules = {
          required: value => !!value || this.register_rules_txt_1,
          password: [
            value => !!value || this.register_rules_txt_2,
            (value) => !!value || this.register_rules_txt_3,
            // (value) => (value && /\d/.test(value)) || 'At least one digit',
            // (value) => (value && /[A-Z]{1}/.test(value)) || 'At least one capital latter',
            (value) => (value && /[^A-Za-z0-9]/.test(value)) || this.register_rules_txt_4,
            (value) => (value && value.length > 9 ) || this.register_rules_txt_5,
          ],
          // equalPassword: value => value===this.password || 'Please reenter password',
          email: value => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value) || this.register_rules_txt_6,
          civil_id: value => this.validarCPF(value) || this.register_rules_txt_7
        }
      },
      validarCPF(cpf) {
        // Remove caracteres não numéricos
        cpf = cpf.replace(/[^\d]+/g, '');

        // Verifica se o CPF tem 11 dígitos
        if (cpf.length !== 11) return false;

        // Verifica se todos os dígitos são iguais (caso inválido)
        if (/^(\d)\1+$/.test(cpf)) return false;

        // Validação do primeiro dígito verificador
        let soma = 0;
        for (let i = 0; i < 9; i++) {
          soma += parseInt(cpf.charAt(i)) * (10 - i);
        }

        let resto = (soma * 10) % 11;
        if (resto === 10 || resto === 11) resto = 0;
        if (resto !== parseInt(cpf.charAt(9))) return false;

        // Validação do segundo dígito verificador
        soma = 0;
        for (let i = 0; i < 10; i++) {
          soma += parseInt(cpf.charAt(i)) * (11 - i);
        }

        resto = (soma * 10) % 11;
        if (resto === 10 || resto === 11) resto = 0;
        if (resto !== parseInt(cpf.charAt(10))) return false;

        return true;
      },
      formatCPF() {
        let value = this.civil_id.replace(/\D/g, '')

        if (value.length <= 11) {
          value = value.replace(/(\d{3})(\d)/, '$1.$2');
          value = value.replace(/(\d{3})(\d)/, '$1.$2');
          value = value.replace(/(\d{3})(\d{1,2})$/, '$1-$2');
        }

        // Atualiza o valor do v-model
        this.civil_id = value;
      },
      ...mapActions(['register']),
      async checkFormData() {
        this.formMessage=undefined
        this.registerMessage=undefined
        const emailRules = this.rules.email(this.email)
        if(emailRules!=true){
          this.formMessage = emailRules
          return
        }
        var passwordRules = undefined
        const rulesPasswordCopy = [...this.rules.password];
        rulesPasswordCopy.forEach((key, index, arr) => {
          passwordRules = key(this.password)
          if(passwordRules!=true){
            // console.log('password rule: ',key, key(this.password))  
            arr.length = index + 1
          }
        })
        if(passwordRules!=true){
          this.formMessage = passwordRules
          return
        }
        if(this.password!=this.confirmPassword){
          this.formMessage = 'Confirm password not equal Password'
          return
        }
        if(this.rules.required(this.first_name)!=true){
          this.formMessage = 'First name field required'
          return
        }
        if (!this.form) return
        this.readTerms=!this.readTerms
      },
      async registerClick() {
        this.formMessage=undefined
        this.registerMessage=undefined
        if(!this.terms_agreement) {
          this.registerMessage = 'You need to agree with the Terms Of Use before register'
          return
        }
        if(!this.policy_agreement) {
          this.registerMessage = 'You need to agree with the Privacy Policy before register'
          return
        }
        this.loading = true
        const res = await this.register(
          {
            "email": this.email,
            "password": this.password,
            "first_name": this.first_name,
            "last_name": this.last_name,
            "phone": this.phone,
            "civil_id": this.civil_id,
            "work_id": this.work_id,
            "currency": this.currency,
            "exchange": this.exchange,
            "terms_agreement": this.terms_agreement,
            "use_terms": this.terms_agreement,
            "privacy_terms": this.policy_agreement,
            "extra": {
              "version": "1.0.0"
            }
          }
        )
        this.loading = false
        if(res==true) this.registered=res
        // else this.registerMessage = 'Registration failed. Perhaps this user is already registered. If this is your e-mail and you never registered in the app, contact our support team sending a message to support@wwcalc.com'
        else this.registerMessage = res
      }
    },
    // directives: {
    //   mask: {
    //     bind(el) {
    //       el.addEventListener('input', () => {
    //         let value = el.value.replace(/\D/g, ''); // Remove todos os caracteres não numéricos

    //         // Formatação CPF ###.###.###-##
    //         if (value.length <= 11) {
    //           value = value.replace(/(\d{3})(\d)/, '$1.$2');
    //           value = value.replace(/(\d{3})(\d)/, '$1.$2');
    //           value = value.replace(/(\d{3})(\d{1,2})$/, '$1-$2');
    //         }

    //         el.value = value;
    //         el.dispatchEvent(new Event('input'));
    //       });
    //     },
    //   },
    // },
  }
</script>
